// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255,255,255;
  --ion-color-contrast: #2CA0A5;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #c8c8c8;
  --ion-color-tint: #ffff1a;
}

/** Ionic CSS Variables **/
:root {
  --ion-text-color-rgb: 0,0,0;

  /** primary **/
  --ion-color-primary: #2CA0A5;
  --ion-color-primary-rgb: #ffffff;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #465555;
  --ion-color-primary-tint: #ffffff;

  /** secondary **/
  --ion-color-secondary: #465555;
  --ion-color-secondary-rgb: 70,85,85;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #333c3c;
  --ion-color-secondary-tint: #566767;

  /** tertiary **/
  --ion-color-tertiary: #38c4bb;
  --ion-color-tertiary-rgb: 56,196,187;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #29948D;
  --ion-color-tertiary-tint: #38c4bb;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #FEC83E;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #FF646C;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #FF646C;
  --ion-color-danger-tint: #FF646C;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #929397;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #29948D;
//     --ion-color-primary-rgb: 41, 148, 141;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #465555;
//     --ion-color-primary-tint: #33beb5;

//     --ion-color-secondary: #465555;
//     --ion-color-secondary-rgb: 70,85,85;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #333c3c;
//     --ion-color-secondary-tint: #566767;

//     --ion-color-tertiary: #38c4bb;
//     --ion-color-tertiary-rgb: 56,196,187;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #29948D;
//     --ion-color-tertiary-tint: #38c4bb;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #465555;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #141818;
//     --ion-background-color-rgb: 20,24,24;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #181818;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-toolbar-background: #212929;

//     --ion-item-background: #141818;

//     --ion-card-background: #212929;
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-border-color: #222222;
//     --ion-tab-bar-background: #1f1f1f;

//     --ion-background-color: #141818;
//     --ion-background-color-rgb: 20,24,24;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #181818;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-toolbar-background: #212929;

//     --ion-item-background: #141818;

//     --ion-card-background: #212929;
//   }
// }

