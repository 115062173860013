@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "swiper/scss";
@import '@ionic/angular/css/ionic-swiper';
@import "@angular/material/theming";
@include mat-core();
$cdq: (
  50: #e1eff0,
  100: #b4d7d9,
  200: #82bdc0,
  300: #50a3a6,
  400: #2b8f93,
  500: #2ca0a5,
  600: #047378,
  700: #04686d,
  800: #035e63,
  900: #014b50,
  A100: #83f5ff,
  A200: #50f1ff,
  A400: #1dedff,
  A700: #03ebff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$cdqdanger: (
  50: #ffe8ed,
  100: #ffc7d1,
  200: #ffa1b2,
  300: #ff7b93,
  400: #ff5f7c,
  500: #ff4365,
  600: #ff3d5d,
  700: #ff3453,
  800: #ff2c49,
  900: #ff1e37,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffced2,
  A700: #ffb4bb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$my-app-primary: mat-palette($cdq, 500);
$my-app-accent: mat-palette($mat-pink, 500, 900, A100);
$my-app-warn: mat-palette($cdqdanger);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);
@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsItalic";
  src: url(./assets/fonts/Poppins-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsB";
  src: url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsItalicB";
  src: url(./assets/fonts/Poppins-BoldItalic.ttf) format("truetype");
}
.poppins {
  font-family: "Poppins" !important;
}
.poppinsItalic {
  font-family: "PoppinsItalic";
}
.poppinsB {
  font-family: "PoppinsB" !important;
}
.poppinsItalicB {
  font-family: "PoppinsItalicB";
}
.modal-starter {
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.modal-starter::part(handle) {
  background: var(--ion-color-primary);
}
.modal-map::part(handle) {
  display: none;
}

.ion-item-form {
  margin-top: 10px;
  //--background: rgb(245 245 245 / 80%);
  --border-radius: 4px;
}
.modal-home-list {
  --height: 86%;
}
.swiper-mobile {
  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }
}
.swiper-category {
  margin: none !important;
  margin-left: none !important;
  margin-right: none !important;
  .swiper-slide {
    width: 80px !important;
    display: flex;
    justify-content: center;
  }
}
.swiper-slide img {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}
@media screen and (min-width: 500px) {
  ion-tab-bar {
    position: fixed;
    right: 55px;
    max-height: 55px;
  }

  .modal-payment {
    --width: 95%;
    --height: 100%;
  }
}

.load-alert .loading-wrapper > .loading-content {
  color: white !important;
  font-family: "Poppins";
  font-size: 30px;
}
.load-alert .loading-wrapper {
  max-height: inherit !important;
  max-width: inherit !important;
  height: 100vh !important;
  width: 100vw !important;
  color: white;
  --background: #2ca0a5;
  --spinner-color: white;
}
.load-alert {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.alert-basic {
  .alert-wrapper {
    .alert-head {
      .alert-title {
        color: var(--ion-color-primary) !important;
      }
    }
    .alert-button-group {
      .alert-button {
        font-weight: bold;
        color: var(--ion-color-primary) !important;
      }
      .alert-button-role-cancel {
        font-weight: bold;
        color: var(--ion-color-danger) !important;
      }
    }
  }
}
.alert-success {
  .alert-wrapper {
    background: var(--ion-color-primary);
    .alert-head {
      .alert-title {
        color: white !important;
      }
    }
    .alert-message {
      color: white !important;
      font-weight: bold !important;
      background: var(--ion-color-primary) !important;
      padding: 15px !important;
      border: none !important;
    }
    .alert-button-group {
      background: var(--ion-color-primary) !important;
      border-radius: 0px !important;
      border: none !important;
      .alert-button {
        color: white !important;
        font-weight: bold;
      }
      .alert-button-role-cancel {
        color: var(--ion-color-danger) !important;
        font-weight: bold;
      }
    }
  }
}
.alert-danger {
  .alert-wrapper {
    background: var(--ion-color-danger);
    .alert-head {
      .alert-title {
        color: white;
      }
    }
    .alert-message {
      color: white !important;
      font-weight: bold !important;
      background: var(--ion-color-danger) !important;
      padding: 15px !important;
      border: none !important;
    }
    .alert-button-group {
      background: var(--ion-color-danger) !important;
      border-radius: 0px !important;
      border: none !important;
      .alert-button {
        color: white !important;
      }
    }
  }
}
.toast {
  --height: auto;
  --background: white;
  --border-radius: 20px;
  --button-color: #2ca0a5;
  --color: #2ca0a5;
  --width: fit-content;
  --font-weight: bold;
}
.toast-button {
  padding-left: unset;
  padding-right: unset;
  font-size: 12px;
  padding: 10px;
  height: 100px;
}
ion-card {
  background-color: #f9f9f9;
}
* {
  font-family: "Poppins";
}
.grey-text {
  color: #4d4d4d;
}
.grey-lighten-text {
  color: #919191;
}
.red-text {
  color: #ff3b3b !important;
}
.white-text {
  color: #ffffff;
}
.color-text {
  color: var(--ion-text-color) !important;
}
/* Background */
.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-lighten-grey {
  background-color: rgb(245, 245, 245);
}
.bg-card {
  background-color: var(--ion-card-background);
}
/* Border */
.border-2 {
  border-radius: 2px;
}
.border-8 {
  border-right: 8px !important;
}
/* Margin */
.margin-auto {
  margin: auto !important;
}
.margin-t-d-10 {
  margin-top: -10px !important;
}
.margin-t-d-20 {
  margin-top: -20px !important;
}
.z-depth-1 {
  box-shadow: 0 1px 2px rgba(var(--ion-text-color-rgb), 0.14),
    0 0 1px rgba(var(--ion-text-color-rgb), 0.12),
    0 1px 5px rgba(var(--ion-text-color-rgb), 0.2);
}
.z-depth {
  box-shadow: 0 1px 0 rgba(var(--ion-text-color-rgb), 0.14),
    0 0 3px rgba(var(--ion-text-color-rgb), 0.12),
    0 0 2px rgba(var(--ion-text-color-rgb), 0.2);
}
.action-sheet {
  .alert-wrapper {
    position: absolute !important;
    bottom: 0px !important;
    width: 100% !important;
    height: 60% !important;
    padding-bottom: 44px !important;
  }
  .alert-checkbox-group {
    max-height: 100% !important;
  }
  .alert-button-group {
    background: #2ca0a5;
    position: absolute;
    bottom: 0px;
    height: 44px;
    button {
      width: 100%;
      position: absolute;
      bottom: 0px;
      span {
        color: white;
        position: relative;
        padding-right: 50%;
        margin-left: 20%;
      }
    }
  }
}
.cdq-header {
  padding: 5px;
  height: 100%;
  width: 80%;
  max-height: 100%;
  max-width: 100%;
}
.cdq-header-web {
  padding: 7px;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 70px;
  cursor: pointer;
}
.cdq-header-web:hover {
  transform: scale(1.01);
}
.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
.alert-danger .alert-wrapper {
  --background: var(--ion-color-danger);
}
.alert-danger .alert-wrapper > .alert-message {
  color: white;
  font-weight: bold;
}
.alert-info .alert-wrapper {
  --background: var(--ion-color-primary) !important;
}
.alert-info .alert-wrapper > .alert-message {
  color: white !important;
  font-weight: bold !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.sep {
  margin-top: 25px;
  margin-bottom: 10px;
  height: 1px;
  background: #2ca0a5;
}
.ellipsis {
  text-overflow: ellipsis;
}
.text-ellipsis-2 {
  display: -webkit-box;
  width: 100%;
  height: fit-content;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  padding-left: 5px;
}
.text-ellipsis-1 {
  display: -webkit-box;
  width: 100%;
  height: fit-content;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}
.text-ellipsis-1-more {
  display: -webkit-box;
  width: 100%;
  height: fit-content;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}
@media screen and (min-width: 1000px) {
  .margin-page {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (max-width: 999px) {
  .margin-page {
    padding-left: 10px;
    padding-right: 10px;
  }
}


.image-gradient {
  background: linear-gradient(transparent, rgb(0 0 0 / 50%) 90%);
}

.bg-success {
  background-color: var(--ion-color-success);
  --background: var(--ion-color-success);
}
.bg-danger {
  background-color: var(--ion-color-danger) !important;
  --background: var(--ion-color-danger);
}
.bg-trans {
  background: transparent;
  --background: transparent;
}
.bg-white {
  background: white;
  --background: white;
}
.bg-primary {
  background: var(--ion-color-primary);
  --background: var(--ion-color-primary);
}
.bg-warning {
  background: var(--ion-color-warning);
  --background: var(--ion-color-warning);
}
.bg-secondary {
  background: var(--ion-color-secondary);
  --background: var(--ion-color-secondary);
}
.bg-beige {
  background-color: white;
  --background: white;
}
.bg-black {
  background: black;
}
.bg-grey {
  background: grey;
  --background: grey;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inherit {
  position: inherit;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-10 {
  bottom: 10px;
}
.bottom-20 {
  bottom: 20px;
}
.bottom-30 {
  bottom: 30px;
}
.bottom-40 {
  bottom: 40px;
}
.bottom-half {
  bottom: 50%;
}
.top-0 {
  top: 0px;
}
.top-10 {
  top: 10px;
}
.right-0 {
  right: 0px;
}
.right-10 {
  right: 10px;
}
.left-0 {
  left: 0px;
}
.left-half {
  left: 50%;
}
.right-20 {
  right: 20px;
}
.right-half {
  right: 50%;
}
.left-20 {
  left: 20px;
}
.top-half {
  top: 50%;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-100 {
  z-index: 100;
}
.z-1000 {
  z-index: 1000;
}
.z-10000 {
  z-index: 10000;
}
.z--1 {
  z-index: -1;
}
.fs-8 {
  font-size: 9px;
}
.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-27 {
  font-size: 27px !important;
}
.fs-29 {
  font-size: 29px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-35 {
  font-size: 35px;
}
.fs-40 {
  font-size: 40px;
}
.fs-50 {
  font-size: 50px;
}
.fs-60 {
  font-size: 60px;
}
.fs-80 {
  font-size: 80px;
}
.fs-100 {
  font-size: 100px;
}

.uppercase {
  text-transform: uppercase;
}
.grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.p-0 {
  padding: 0px !important;
}
.p-5 {
  padding: 5px;
}
.p-6 {
  padding: 6px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-80 {
  padding: 80px;
}
.p-100 {
  padding: 100px;
}
.pl-3 {
  padding-left: 3px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-23 {
  padding-left: 23px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-100 {
  padding-left: 100px;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-7 {
  padding-right: 7px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-100 {
  padding-right: 100px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-100 {
  padding-top: 100px;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.w-quarter {
  width: 25%;
}
.w-fit {
  width: fit-content;
}
.w-0 {
  width: 0px !important;
}
.w-10 {
  width: 10px;
}
.w-20 {
  width: 20px;
}
.w-25 {
  width: 25px;
}
.w-30 {
  width: 30px;
}
.w-40 {
  width: 40px;
}
.w-50 {
  width: 50px;
}
.w-60 {
  width: 60px;
}
.w-80 {
  width: 80px;
}
.w-100 {
  width: 100px;
}
.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}
.w-240 {
  width: 240px;
}
.w-300 {
  width: 300px;
}
.w-350 {
  width: 350px;
}
.w-400 {
  width: 400px;
}
.w-450 {
  width: 450px;
}
.h-full {
  height: 100%;
}
.h-half {
  height: 50%;
}
.h-quarter {
  height: 25%;
}
.h-fit {
  height: fit-content;
}
.h-0 {
  height: 0px !important;
}
.h-10 {
  height: 10px;
}
.h-20 {
  height: 20px;
}
.h-25 {
  height: 25px;
}
.h-27 {
  height: 27px;
}
.h-30 {
  height: 30px;
}
.h-35 {
  height: 35px;
}
.h-40 {
  height: 40px;
}
.h-44 {
  height: 44px;
}
.h-45 {
  height: 45px;
}
.h-50 {
  height: 50px;
}
.h-60 {
  height: 60px;
}
.h-70 {
  height: 70px;
}
.h-80 {
  height: 80px;
}
.h-88 {
  height: 88px;
}
.h-100 {
  height: 100px;
}
.h-150 {
  height: 150px;
}
.h-200 {
  height: 200px;
}
.h-240 {
  height: 240px;
}
.h-250 {
  height: 250px;
}
.h-300 {
  height: 300px;
}
.h-350 {
  height: 350px;
}
.h-400 {
  height: 400px;
}
.h-450 {
  height: 450px;
}
.h-2rem {
  height: 2rem;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-100 {
  margin-top: 100px;
}
.m-0 {
  margin: 0px;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.italic {
  font-style: italic;
}
.underline {
  text-underline-position: under;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.underline-warning {
  text-decoration-color: var(--ion-color-warning);
}
.text-justify {
  text-align: justify;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-start {
  text-align: start;
}
.font-bold {
  font-weight: bold;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.border-radius-5 {
  border-radius: 5px;
  --border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
  --border-radius: 10px;
}
.border-radius-20 {
  border-radius: 20px;
  --border-radius: 20px;
}
.border-radius-30 {
  border-radius: 30px;
  --border-radius: 30px;
}
.border-radius-100 {
  border-radius: 100%;
  --border-radius: 100%;
}
.border-radius-half {
  border-radius: 50%;
  --border-radius: 50%;
}
.border-bottom {
  border-bottom: 1px;
}
.border-solid {
  border-style: solid;
}
.border-none {
  border: none;
}
.pointer-events-none {
  pointer-events: none;
}
.user-select-none {
  user-select: none;
}
.outline-none {
  outline: none;
}
.white {
  color: white;
}
.black {
  color: black;
}
.primary {
  color: #2CA0A5;
  --color: #2CA0A5;
}
.secondary {
  color: var(--ion-color-secondary);
  --color: var(--ion-color-secondary);
}
.warning {
  color: var(--ion-color-warning);
  --color: var(--ion-color-warning);
}
.display-none {
  display: none;
}
.overflow-auto {
  overflow: auto;
}
.object-cover {
  object-fit: cover;
}
.object-contain {
  object-fit: contain;
}
.object-fill {
  object-fit: fill;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inherit {
  position: inherit;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-100 {
  z-index: 100;
}
.z-200 {
  z-index: 200;
}
.z-1000 {
  z-index: 1000;
}
.z-10000 {
  z-index: 10000;
}
.z-100000 {
  z-index: 100000;
}
.z--1 {
  z-index: -1;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-25 {
  font-size: 25px;
}
.fs-27 {
  font-size: 27px;
}
.fs-29 {
  font-size: 29px;
}
.fs-30 {
  font-size: 30px;
}
.fs-35 {
  font-size: 35px;
}
.fs-40 {
  font-size: 40px;
}
.fs-50 {
  font-size: 50px;
}
.fs-80 {
  font-size: 80px;
}
.fs-100 {
  font-size: 100px;
}
.grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.p-3 {
  padding: 3px;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.p-40 {
  padding: 40px;
}
.p-50 {
  padding: 50px;
}
.p-100 {
  padding: 100px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-23 {
  padding-left: 23px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-100 {
  padding-left: 100px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-100 {
  padding-right: 100px;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-100 {
  padding-top: 100px;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.w-quarter {
  width: 25%;
}
.w-fit {
  width: fit-content;
}
.w-0 {
  width: 0px !important;
}
.w-5 {
  width: 5px;
}
.w-10 {
  width: 10px;
}
.w-12 {
  width: 12px;
}
.w-20 {
  width: 20px;
}
.w-30 {
  width: 30px;
}
.w-40 {
  width: 40px;
}
.w-75 {
  width: 75px;
}
.w-80 {
  width: 80px;
}
.w-100 {
  width: 100px;
}
.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}
.w-300 {
  width: 300px;
}
.w-360 {
  width: 360px;
}
.w-400 {
  width: 400px;
}
.w-450 {
  width: 450px;
}
.w-500 {
  width: 500px;
}
.mw-500 {
  max-width: 500px;
}
.h-full {
  height: 100%;
}
.h-half {
  height: 50%;
}
.h-quarter {
  height: 25%;
}
.h-fit {
  height: fit-content;
}
.h-12 {
  height: 12px;
}
.h-75 {
  height: 75px;
}
.h-120 {
  height: 120px;
}
.h-140 {
  height: 140px;
}
.h-160 {
  height: 160px;
}
.h-500 {
  height: 500px;
}
.h-600 {
  height: 600px;
}
.table {
  display: table;
}
.mr-15 {
  margin-right: 15px;
}
.mr-35 {
  margin-right: 35px;
}
.m-60 {
  margin: 60px;
}
.mb-100 {
  margin-bottom: 100px;
}
.border-top {
  border-top: 1px;
}
.border-left {
  border-left: 1px;
}
.border-right {
  border-right: 1px;
}
.border-grey {
  border: 1px solid grey;
}
.grey {
  color: grey;
}
.gold {
  color: rgb(211, 189, 119);
  --color: rgb(211, 189, 119);
}
.red {
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
